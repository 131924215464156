import "../styles/globals.css";
import { useEffect } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import * as gtag from "../../lib/gtag";
import { ThemeProvider } from "next-themes";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const sendMetric = ({ name, value }) => {
  if (process.env.NEXT_PUBLIC_QUICK_METRICS_API_KEY) {
    const url = `https://qckm.io?m=${name}&v=${value}&k=${process.env.NEXT_PUBLIC_QUICK_METRICS_API_KEY}`;

    // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
    if (navigator.sendBeacon) {
      navigator.sendBeacon(url);
    } else {
      fetch(url, { method: "POST", keepalive: true });
    }
  }
};

export function reportWebVitals({ id, name, label, value }) {
  window.gtag("event", name, {
    event_category:
      label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
    value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
    event_label: id, // id unique to current page load
    non_interaction: true, // avoids affecting bounce rate.
  });
  if (label === "web-vital") {
    sendMetric({
      name,
      value: Math.round(name === "CLS" ? value * 1000 : value),
    });
  }
}

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/images/logos/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/logos/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/logos/favicon-16x16.png"
        />
        <link rel="manifest" href="/images/logos/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/images/logos/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <link rel="shortcut icon" href="/images/logos/favicon.ico" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta
          name="msapplication-config"
          content="/images/logos/browserconfig.xml"
        />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <ThemeProvider attribute="class" enableSystem={true}>
        <Navbar />
        <Component {...pageProps} />
        {/* <Footer /> */}
      </ThemeProvider>
    </>
  );
};

export default App;
